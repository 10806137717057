import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParse from 'dayjs/plugin/customParseFormat';
import { Button, Form, Row, Col, Input, Divider, message, Badge, Select } from 'antd';
import { LinkOutlined, SendOutlined } from '@ant-design/icons';
import { sendNotification } from '../../actions';
import Upload from '../shared/Upload';

dayjs.extend(customParse).extend(utc);

const setFormErrors = (form, error, values) => {
    const {
        res: { errors: serverErrors, message: msg },
    } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

const makeProperCase = (text) =>
    text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

function NewNotification(props) {
    const { marinaId, notification, sendNotification, onFinish: onComplete } = props;
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [titleCount, setTitleCount] = useState(60);
    const [bodyCount, setBodyCount] = useState(200);
    const [form] = Form.useForm();
    const [documentUrl, setDocumentUrl] = useState(null);
    const initialValues = {
        type: 'standard',
        title: '',
        message: '',
        document_url: '',
    };
    const [selectedType, setSelectedType] = useState('standard');
    const showDocUpload = useMemo(() => selectedType === 'notice', [selectedType]);

    useEffect(() => {
        if (!notification.error) return;
        setFormErrors(form, notification.error, values);
    }, [notification.error]);

    const onFinish = (formValues) => {
        setValues(formValues);
        setSubmitted(true);
        sendNotification({ ...formValues, document_url: documentUrl });
    };

    useEffect(() => {
        if (!submitted || notification.isFetching) return;
        setSubmitted(false);
        if (!notification.error) {
            setTitleCount(60);
            setBodyCount(200);
            form.resetFields();
            setDocumentUrl(null);
            setSelectedType('standard');
            message.success('Successfully sent');
            onComplete(true);
        }
    }, [notification.isFetching]);

    const onTypeChange = (value) => {
        form.setFieldsValue({ title: 'New Notice to Mariners' });
        setSelectedType(value);
    };

    const updateCount = (fn, max) => (e) => {
        e.persist();
        fn(() => max - e.target.value.length);
    };

    const handleFileUploadComplete = ({ url }) => {
        setDocumentUrl(url);
    };

    const NewNotificationForm = (
        <Form
            name="new-notification-form"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Type is required' }]}>
                        <Select onChange={onTypeChange}>
                            <Select.Option value="standard">Standard</Select.Option>
                            <Select.Option value="notice">Notice to Mariners</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                Title <Badge count={titleCount} showZero />
                            </span>
                        }
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Title is required',
                                whitespace: true,
                            },
                            {
                                message: 'Title cannot be more than 60 letters',
                                max: 60,
                            },
                        ]}
                        normalize={makeProperCase}
                        hasFeedback
                    >
                        <Input
                            disabled={selectedType === 'notice'}
                            placeholder="Please enter notification title"
                            onChange={updateCount(setTitleCount, 60)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                Body <Badge count={bodyCount} showZero />
                            </span>
                        }
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: 'Message body is required',
                                whitespace: true,
                            },
                            {
                                message: 'Body cannot be more than 200 letters',
                                max: 200,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.TextArea
                            rows={6}
                            placeholder="Please enter the message body"
                            onChange={updateCount(setBodyCount, 200)}
                        />
                    </Form.Item>

                    {showDocUpload && !documentUrl && (
                        <Form.Item label="Notice Document">
                            <Upload
                                type="other"
                                extra={{ marina: marinaId, name: 'mariners_notice' }}
                                onUploadComplete={handleFileUploadComplete}
                            />
                        </Form.Item>
                    )}
                    {showDocUpload && documentUrl && (
                        <Form.Item label="Notice Document">
                            <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                                <LinkOutlined /> Notice to Mariners
                            </a>
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Divider />
            <div style={{ float: 'right' }}>
                <Button type="primary" htmlType="submit" loading={notification.isFetching}>
                    <SendOutlined /> Send
                </Button>
            </div>
        </Form>
    );

    return NewNotificationForm;
}

const mapStateToProps = (state, ownProps) => {
    const {
        notification,
        me: { marina_id: marinaId },
    } = state;

    return {
        notification,
        marinaId,
    };
};

export default connect(mapStateToProps, {
    sendNotification,
})(NewNotification);
